<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">测评管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">测评结果统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox searMg">
            <div title="测评问卷名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem;">测评问卷名称:</span>
              <el-input v-model="retrievalData.paperName" type="text" size="small" placeholder="请输入测评问卷名称" clearable />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="测评问卷名称" align="left" show-overflow-tooltip prop="paperName" minWidth="150" />
              <el-table-column label="分类" align="left" show-overflow-tooltip prop="evaluateCategory" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("HR_EVALUATE_CATEGORY", scope.row.evaluateCategory) }}
                </template>
              </el-table-column>
              <el-table-column label="来源" align="left" show-overflow-tooltip prop="paperSource" minWidth="180" />
              <el-table-column label="答题人数" align="left" show-overflow-tooltip prop="peopleNum" minWidth="100" />
              <!-- <el-table-column label="用券时间" align="left" prop="endDate" show-overflow-tooltip min-width="240">
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }} - {{ scope.row.endTime | moment }}
                </template>
              </el-table-column> -->
              <el-table-column label="操作" align="center" width="180px">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="goResultsSummary(scope.row)">结果汇总</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluationManagement_evaluationResults",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        paperName: "", // 优惠券名称
      },
    };
  },
  created() {

  },
  watch: {},
  computed: {},
  mounted() {},
  beforeRouteLeave: resetKeepAlive,
  methods: {
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.paperName) {
        params.paperName = this.retrievalData.paperName;
      }
      this.doFetch({
        url: "/biz/hr/evaluate/paper/resultPageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 跳转 - 结果汇总
    goResultsSummary(row){
      this.$router.push({
        path: "/web/evaluationManagement/evaluationResultsSummary",
        query: {
          paperId: row.paperId,
        },
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 通过 `vm` 访问组件实例
      if (from.path == "/web/evaluationManagement/evaluationResultsSummary") {
        vm.getData(vm.pageNum);
      }
    });
  },
};
</script>
<style lang="less" scoped></style>